import theme, { withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export type AlertContainerProps = StyledSystemProps & {
  visible?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  color?: string;
  infoBgColor?: string;
  infoFgColor?: string;
  successBgColor?: string;
  successFgColor?: string;
  errorBgColor?: string;
  errorFgColor?: string;
  warningBgColor?: string;
  warningFgColor?: string;
};

function getBackgroundColor({
  success,
  warning,
  error,
  successBgColor,
  warningBgColor,
  errorBgColor,
  infoBgColor,
}: AlertContainerProps) {
  if (success) {
    return successBgColor;
  }
  if (warning) {
    return warningBgColor;
  }
  if (error) {
    return errorBgColor;
  }
  return infoBgColor;
}

function getColor({
  success,
  warning,
  error,
  successFgColor,
  warningFgColor,
  errorFgColor,
  infoFgColor,
}: AlertContainerProps) {
  if (success) {
    return successFgColor;
  }
  if (warning) {
    return warningFgColor;
  }
  if (error) {
    return errorFgColor;
  }
  return infoFgColor;
}

export const AlertBox = styled.div<{ visible: boolean; position?: number | null }>`
  z-index: 99999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ position }) => (position ? `${position}px` : '24px')};
  background: transparent;
  user-select: none;
  pointer-events: none;
  display: flex;
  justify-content: center;
  min-height: 56px;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: translate3d(0, ${({ visible }) => (visible ? 0 : 62)}px, 0);
  transition:
    transform 300ms ease 0ms,
    opacity 150ms linear 0ms;
  transition-duration: ${({ visible }) => (visible ? '400ms,250ms' : '300ms,150ms')};
`;

export const AlertContainer = styled.button<AlertContainerProps>`
  background-color: ${getBackgroundColor};
  outline: none;
  padding: 16px 24px;
  font-size: 16px;
  letter-spacing: 0.015em;
  min-width: 100px;
  line-height: 24px;
  font-weight: 500;
  color: ${getColor};
  text-align: center;
  user-select: none;
  pointer-events: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 32px;
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.22);

  ${withStyledSystem}
`;
AlertContainer.defaultProps = {
  type: 'button',
  infoBgColor: theme.colors.blue2,
  infoFgColor: theme.colors.blue7,
  successBgColor: theme.colors.green1,
  successFgColor: theme.colors.green4,
  errorBgColor: theme.colors.coral,
  errorFgColor: theme.colors.orange1,
  warningBgColor: theme.colors.yellow1,
  warningFgColor: theme.colors.grey6,
};
