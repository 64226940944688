import { useEffect } from 'react';

import { showAlert } from '@m/state/alert';
import { handleQRCode, parseQRDelegation } from '@m/state/qrcode';
import { add } from 'date-fns';
import lStorage from 'local-storage-fallback';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';

import { ProtectedRoutes } from 'apps/customer/routes/paths';
import {
  setPromptUserSignIn,
  setSignInLandingPage,
  setSignInLandingPageState,
} from 'apps/customer/state/route';
import {
  getUser,
  logout,
  parseInvitationCode,
  parseEnterpriseInviteToken,
  setTokenAndUserInfoForEnterpriseInvitation,
} from 'apps/customer/state/user';
import {
  customerApplyValidation,
  fetchCustomerVisits,
  setQRValidation,
  setDoorOpenRequest,
} from 'apps/customer/state/visit';
import {
  SCANNED_NATIVE,
  trackValidationResponse,
  trackValidationScan,
} from 'apps/customer/utils/validationHeap';

/**
 * Initializes the customer app state,
 * by initiatied requests based off of the url and fetching the user
 *
 * This hook checks:
 * - If coming from enterprise invite link, parse code and fetch enterprise user info
 * - If coming from an invitation link, parse code and get user back
 * - If scanning a join QR, ask BE to provide the tasks (todoItems) and user if available
 * - If referrer is mpolis.io, set the initial landing page based off referrer pathname
 * - If scanning a validation QR, store the validationUUID so it can be applied after signup/signin
 * - If param access is present it will save the value to open a pedestrian door (customer scanned qr code)
 */
export function useUserInitialization() {
  const location = useLocation();

  // If user is already logged in and they try to pass an invitation code, they will be logged out
  // If an invite is passed then we will always display the login or welcome
  const parseInviteCodeAndLogoutIfFailed = async (invitationCode: string) => {
    const response = await parseInvitationCode({ invitationCode });
    if (!response.success) {
      logout();
      showAlert({ label: 'No valid invitation found!', type: 'error' });
    }
  };

  const vehicleSettingsInitialPathCheck = (initalPath: string) => {
    if (initalPath.match(/^\/vehicle\/[0-9A-Za-z]+\/drivers$/)) return true;
    if (initalPath.match(/^\/vehicle\/[0-9A-Za-z]+$/)) return true;
    if (initalPath.match(/^\/vehicle\/?$/)) return true;
    return false;
  };

  useEffect(() => {
    const { pathname, search } = location;
    const {
      invitationCode,
      validation,
      path,
      access,
      ent_token: entToken,
      token,
      firstName,
      lastName,
      employeeId,
      email,
    } = parse(search);

    if (access) {
      setDoorOpenRequest(access as string);
    }

    // LANDS ON: app[env].metropolis.io/welcome?invitationCode=:uuid
    // TAKES TO: first time users - /welcome sign-up flow || existing user - /visit via auto login
    const gotHereByInvitationCode =
      invitationCode && typeof invitationCode === 'string' && pathname === '/welcome';

    // LANDS ON: app[env].metropolis.io/visit/start/:uuid
    // TAKES TO: /onboard
    const gotHereByStartVisitQRCode = pathname.match(/\/visit\/start\/[a-zA-Z0-9-]+/);

    // LANDS ON: app[env].metropolis.io/join/:uuid
    // TAKES TO: /onboard
    const gotHereByJoinQRCode = pathname.match(/\/join\/[a-zA-Z0-9-]+/);

    // LANDS ON: app[env].metropolis.io/?validation=:uuid
    // TAKES TO: /onboard
    const gotHereByValidationQRCode = validation;

    // LANDS ON: app[env].metropolis.io/login?ent_token=:uuid
    // TAKES TO: first time users - /sign-in || existing user - /membership
    const gotHereByEnterpriseInvitation = !!entToken;

    const gotHereByEnterpriseInvitationOnboardingToken = !!token && !!employeeId;
    const getUserandSetEnterpriseInfo = async () => {
      await getUser();
      await setTokenAndUserInfoForEnterpriseInvitation({
        enterpriseUser: {
          token: token as string,
          firstName: firstName as string,
          lastName: lastName as string,
          employeeId: employeeId as string,
          email: email as string,
        },
      });
    };

    if (gotHereByEnterpriseInvitation) {
      parseEnterpriseInviteToken({ token: entToken as string });
    } else if (gotHereByInvitationCode) {
      parseInviteCodeAndLogoutIfFailed(invitationCode);
    } else if (gotHereByStartVisitQRCode) {
      const uuid = pathname.split('/')[3];
      handleQRCode({ uuid });
    } else if (gotHereByJoinQRCode) {
      const uuid = pathname.split('/')[2];
      handleQRCode({ uuid });
    } else if (gotHereByValidationQRCode) {
      trackValidationScan({
        validationScanSource: SCANNED_NATIVE,
        pendingValidationUuid: validation as string,
      });
      customerApplyValidation({
        validationUUID: validation as string,
      }).then((response) => {
        if (!response.success || (response.success && response.data.transaction)) {
          // validation successfully applied if we receive a transaction from the API
          // if we receive a txn, then validation is successfully applied and user is logged in
          if (response.success) {
            const {
              transaction: { id: visitId, siteId },
              validation: { id: validationId },
            } = response.data;
            trackValidationResponse({
              appliedValidationSuccess: true,
              appliedValidationUuid: validation as string,
              attemptedValidationId: validationId,
              appliedValidationTransactionId: visitId || 'null',
              appliedValidationSiteId: siteId || 'null',
            });
          } else {
            // if this request fails, then user is logged in
            // fetch visits so we can pass more data to heap
            fetchCustomerVisits().then((visitResponse) => {
              let visitId;
              let siteId;
              if (visitResponse.success && visitResponse.data!.transactions.length > 0) {
                [{ id: visitId, siteId }] = visitResponse.data!.transactions;
              }
              // failed validation response means the user is logged in already. report to heap
              trackValidationResponse({
                appliedValidationSuccess: false,
                appliedValidationUuid: validation as string,
                appliedValidationTransactionId: visitId || 'null',
                appliedValidationSiteId: siteId || 'null',
                appliedValidationErrorMessage: response.error,
              });
            });
            showAlert({ label: 'Validation not applied', type: 'error' });
          }
        }
        if (response.success && response.data?.qrDelegation) {
          parseQRDelegation(response.data.qrDelegation);
        } else {
          getUser();
        }
      });
    } else if (gotHereByEnterpriseInvitationOnboardingToken) {
      getUserandSetEnterpriseInfo();
    } else {
      if (pathname.match(/^\/membership\/?$/)) {
        setSignInLandingPage('/membership');
      } else if (vehicleSettingsInitialPathCheck(pathname)) {
        setSignInLandingPage(pathname);
      } else if (pathname.match(/^\/claim\/[a-zA-Z0-9]+/)) {
        setSignInLandingPage('/promo');
      } else if ((path as string)?.match(/^\/park/)) {
        setSignInLandingPage('/membership');
      } else if ((path as string)?.match(/^\/welcome/)) {
        setPromptUserSignIn(true);
        setSignInLandingPage('/');
      } else if ((path as string)?.match(/^\/v\/[a-zA-Z0-9]+/)) {
        const visitCode = (path as string).split('/')[2];
        setSignInLandingPage(`/visit/${visitCode}`);
      } else if (pathname.match(/^\/visit\/[a-zA-Z0-9]+/)) {
        setSignInLandingPage(pathname);
      } else if ((path as string)?.match(/^\/pass/)) {
        setSignInLandingPage(ProtectedRoutes.ForwardSlash);
        setSignInLandingPageState({ isPurchasingPass: true });
      } else if ((path as string)?.match(/^\/receipt\/[a-zA-Z0-9]+/)) {
        const invoiceUuid = (path as string).split('/')[2];
        setSignInLandingPage(`/receipt/${invoiceUuid}`);
      }
      // No invitation passed. Check cookies
      getUser();
    }

    // Checks local storage for the stored validation
    async function getStoredValidation() {
      const storedVal = lStorage.getItem('mp-validation');
      if (storedVal) {
        const { id: validationId, expire: validationExpire } = JSON.parse(storedVal);
        if (Date.now() < validationExpire) {
          setQRValidation(validationId);
          setSignInLandingPage('/');
        } else {
          lStorage.removeItem('mp-validation');
        }
      }
    }

    // Set validation data
    if (gotHereByValidationQRCode) {
      setQRValidation(validation as string);
      // Store the validation in case the user has not created an account
      const expire = add(new Date(), { days: 1 }).getTime();
      lStorage.setItem('mp-validation', JSON.stringify({ id: validation, expire }));
      setSignInLandingPage('/');
    } else {
      getStoredValidation();
    }

    // onmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
