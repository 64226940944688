import { apiFetch } from '@m/utils/http';
import { createEffect, createEvent } from 'effector';

import STPService from 'apps/customer/services/STPService';
import TodoService from 'apps/customer/services/TodoService';
import { fetchCustomerVisits } from 'apps/customer/state/visit';

import type { TodoItemType } from '@m/types/api';
import type { STPPostConfirmEqlessVisitV2 } from 'apps/customer/services/STPService';

export const fetchTodoItems = createEffect({
  handler: () => TodoService.getTodosV2(),
});

export const fetchPaymentOverdueTask = createEffect({
  handler: () => apiFetch('/customer/payment-due'),
});

export const fetchVisitDetailsEqless = createEffect({
  handler: ({ equipmentlessVisitId }: { equipmentlessVisitId: number }) =>
    apiFetch(`/customer/visit/${equipmentlessVisitId}/get-parameters`),
});

export const optOutEquipmentlessVisit = createEffect({
  handler: ({ equipmentlessVisitId }: { equipmentlessVisitId: number }) =>
    apiFetch(`/customer/visit/${equipmentlessVisitId}/opt-out`, { method: 'POST' }),
});

export const confirmEquipmentlessVisitAndRefreshState = createEffect({
  handler: async ({
    equipmentlessVisitId,
    durationTotalMinutes,
    vehicleId,
  }: {
    equipmentlessVisitId: number;
    durationTotalMinutes: number;
    vehicleId: number;
  }) => {
    const response = await apiFetch(`/customer/visit/${equipmentlessVisitId}/confirm`, {
      method: 'POST',
      body: {
        durationTotalMinutes,
        vehicleId,
      },
    });

    if (response.success) {
      // visits needs to go first, otherwise the page will jump from "About" to "Visit"
      await fetchCustomerVisits();
      await fetchTodoItems();
    }

    return response;
  },
});

// TODO: how should this service look like?
export const confirmEquipmentlessVisitAndRefreshStateV2 = async ({
  equipmentlessVisitId,
  durationTotalMinutes,
  vehicleId,
}: STPPostConfirmEqlessVisitV2) => {
  const response = await STPService.postConfirmEqlessVisitV2({
    equipmentlessVisitId,
    durationTotalMinutes,
    vehicleId,
  });

  if (response.success) {
    // visits needs to go first, otherwise the page will jump from "About" to "Visit"
    await fetchCustomerVisits();
  }
  return response;
};

export const completeTask = createEvent<TodoItemType>();

// #region MISSED_ENTRY_START_VISIT

export const fetchCurrentTodo = createEffect({
  handler: () => apiFetch('/customer/current-todo'),
});

export const confirmMissedEntryStartVisit = createEffect({
  handler: ({
    missedEntryStartVisitTaskId,
    vehicleId,
  }: {
    missedEntryStartVisitTaskId: number;
    vehicleId: number;
  }) =>
    apiFetch(`/customer/missed-entry-start-visit/${missedEntryStartVisitTaskId}/confirm`, {
      method: 'POST',
      body: {
        vehicleId,
      },
    }),
});

export const optOutTodoTask = createEffect({
  handler: ({ customerTaskId }: { customerTaskId: number }) =>
    apiFetch(`/customer/task/${customerTaskId}/opt-out`, {
      method: 'POST',
    }),
});

// #endregion

export const joinFlowOptOut = createEffect({
  handler: () => TodoService.joinFlowOptOut(),
});
