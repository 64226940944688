import { createEvent } from 'effector';

export const setLastVehicleWasPreviouslyAdded = createEvent<boolean | null>();

// LP Correction Specific for metrics
export const setLpSuggestionWasGiven = createEvent<boolean>();
export const setLpSuggestionWasSelected = createEvent<boolean>();
export const setLpCorrectionWasAskedToConfirm = createEvent<boolean>();
export const setLpCorrectionUserCorrectedLp = createEvent<boolean>();
export const setLpCorrectionTransactionId = createEvent<number | null>();
export const setLpCorrectionHasBeenReported = createEvent<boolean>();
export const setLpCorrectionShouldTrack = createEvent<boolean>();
export const resetLpCorrectionMetricState = createEvent<boolean>();
export const setClickedLinkOnConfirmPage = createEvent<boolean>();
