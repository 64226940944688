import { THIRD_PARTY_TRACKING_ENDABLED } from '@m/constants/FeatureFlags';
import getEnvironment from '@m/utils/getEnvironment';
import * as Sentry from '@sentry/react';

import type { AppName } from '@m/types';

import { User } from 'types/api';

export const dsnMap: { [env: string]: string } = {
  admin:
    'https://982bcecd1f567e56bb30285fdec0af2d@o4504596426719232.ingest.us.sentry.io/4507896717443072',
  customer:
    'https://02579a22e42c4f19ad4818c0fa5bfb63@o4504596426719232.ingest.sentry.io/4504605362552832',
  intake:
    'https://16f9f208142adaab4ab1cf80325a113f@o4504596426719232.ingest.us.sentry.io/4508123220279296',
  pay: 'https://7802aa3b976d98a80a4874101911e67f@o4504596426719232.ingest.us.sentry.io/4508123222441984',
  portal:
    'https://c757505b93f75c5de2dee3d9270cf696@o4504596426719232.ingest.us.sentry.io/4508123223949312',
  request:
    'https://34716c1c74b83d8a2d923ae8d8131a85@o4504596426719232.ingest.us.sentry.io/4508123225391104',
  specialist:
    'https://d56092f3b1ad784aebfbd5e612d9f845@o4504596426719232.ingest.us.sentry.io/4508123226767360',
  validation:
    'https://fbcb018cf635110e9e122fce866b656d@o4504596426719232.ingest.us.sentry.io/4508123228078080',
};

function getDsn(appName: AppName) {
  const dsn = dsnMap[appName];

  if (!dsn) {
    throw new Error(`initSentry Error: Unsupported app: ${appName}.`);
  }
  return dsn;
}

export default function initSentry(appName: AppName) {
  try {
    if (THIRD_PARTY_TRACKING_ENDABLED) {
      const dsn = getDsn(appName);
      const environment = getEnvironment();

      Sentry.init({
        dsn,
        environment,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
              window.location.origin,
              /^https?:\/\/([a-zA-Z0-9-]+\.)*metropolis\.io$/,
            ],
          }),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.25,
      });
    }
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line
      console.error(e);
    }
  }
}

export function sentrySetUser(user: User) {
  Sentry.setUser({ ...user } as Sentry.User);
}

export function sentryLogout() {
  Sentry.setUser(null);
}
