import styled from 'styled-components';

export const Box = styled.div`
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  & * {
    box-sizing: inherit;
  }
`;

export const ContentBox = styled.div`
  padding: 24px;
`;

export const DeleteSection = styled(ContentBox)`
  border-top: 1px solid #e5e5e5;
`;

export const Header = styled.header`
  margin-bottom: 32px;
`;

export const PageLabel = styled.div`
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.01em;
`;

export const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
`;

export const DeleteButton = styled.button`
  background: none;
  outline: none;
  border: none;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #ed0006;
  text-align: right;
`;

export const BsContainer = styled.div`
  padding: 0 12px 24px 12px;
  margin-top: -4px;
`;

export const BsHeader = styled.header`
  line-height: 26.4px;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const BsText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #6a6a6a;
  margin-bottom: 1rem;
`;

export const BsControls = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const BsButton = styled.button<{ deleting: boolean }>`
  width: calc(50% - 4px);
  padding: 1rem;
  background: none;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  opacity: ${({ deleting }) => (deleting ? '0.5' : '1')};
`;

export const BsCancelButton = styled(BsButton)`
  color: #3e3e3e;
  margin-right: 8px;
`;

export const BsDeleteButton = styled(BsButton)`
  color: #ed0006;
`;
