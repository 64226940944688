import { createGlobalStyle } from 'styled-components';
import 'react-spring-bottom-sheet/dist/style.css';

export const BottomSheetStyles = createGlobalStyle`
  [data-rsbs-root] {
    position: relative;
    z-index: 5;
  }

  [data-rsbs-header] {
    height: 40px;
  }
  [data-rsbs-overlay] {
    --rsbs-overlay-rounded: 8px;
    z-index: 4 !important;
  }
  [data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
    box-shadow: 0 -3px 22px rgba(0, 0, 0, 0.05),
                0 -4px 2px rgba(0, 0, 0, 0.01),
                0 4px 9px rgba(204, 204, 242, 0.24) !important;
  }
  [data-rsbs-scroll='true'] {
    overflow: hidden !important;
  }
`;
