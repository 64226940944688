/**
 * Parked vehicle statuses
 */
export const UNKNOWN = 'UNKNOWN';
export const PARKED = 'PARKED'; // Self park entry
export const PICKED_UP = 'PICKED_UP'; // Self park exit

export const UNKNOWN_ID = 0;
export const PARKED_ID = 1;
export const PICKED_UP_ID = 2;
