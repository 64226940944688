import { useEffect } from 'react';

import { isRegionValid } from '@m/utils/regionCoordinates';
import lStorage from 'local-storage-fallback';

import { getGeolocationIP, setClosestRegion } from 'apps/customer/state/geolocation';

export function useClosestMetropolisRegion() {
  /* Handle region to display */
  useEffect(() => {
    const data = lStorage.getItem('mp-region');
    if (data !== null) {
      const dataJson: { region: string; expire: number } = JSON.parse(data);
      // Been a month since the last check
      if (Date.now() > dataJson.expire || !isRegionValid(dataJson.region)) {
        getGeolocationIP();
      } else {
        setClosestRegion(dataJson.region);
      }
    } else {
      getGeolocationIP();
    }
  }, []);
}
