import { apiFetch } from '@m/utils/http';

export interface STPPostConfirmEqlessVisitV2 {
  equipmentlessVisitId: number;
  durationTotalMinutes: number;
  vehicleId: number;
}

// we can potentially add return types
const STPService = {
  getVisitDetailsEqless: () => apiFetch('/v2/customer/visit/duration-selected'),

  postEqlessOptOut: ({ equipmentlessVisitId }: { equipmentlessVisitId: number }) =>
    apiFetch(`/customer/visit/${equipmentlessVisitId}/opt-out`, { method: 'POST' }),

  postConfirmEqlessVisit: async ({
    equipmentlessVisitId,
    durationTotalMinutes,
    vehicleId,
  }: {
    equipmentlessVisitId: number;
    durationTotalMinutes: number;
    vehicleId: number;
  }) =>
    apiFetch(`/customer/visit/${equipmentlessVisitId}/confirm`, {
      method: 'POST',
      body: {
        durationTotalMinutes,
        vehicleId,
      },
    }),

  postConfirmEqlessVisitDuration: async ({
    equipmentlessVisitId,
    durationTotalMinutes,
  }: {
    equipmentlessVisitId: number;
    durationTotalMinutes: number;
  }) =>
    apiFetch(`/v2/customer/visit/${equipmentlessVisitId}/confirm-duration`, {
      method: 'POST',
      body: {
        durationTotalMinutes,
      },
    }),

  postConfirmEqlessVisitV2: async ({
    equipmentlessVisitId,
    durationTotalMinutes,
    vehicleId,
  }: STPPostConfirmEqlessVisitV2) =>
    apiFetch(`/v2/customer/visit/${equipmentlessVisitId}/confirm`, {
      method: 'POST',
      body: {
        durationTotalMinutes,
        vehicleId,
      },
    }),

  postSwitchVehicle: ({ vehicleId, visitId }: { vehicleId: number; visitId: number }) =>
    apiFetch(`/v2/customer/visit/${visitId}`, {
      method: 'PUT',
      body: {
        vehicleId,
      },
    }),
};

export default STPService;
