import { applyReducers } from '@m/effector-utils';
import { IdNameObject } from '@m/types';
import { Vehicle, Make, Model, ModelYear } from '@m/types/api';
import { logout } from 'apps/customer/state/user/actions';
import { createStore, Effect, Event } from 'effector';

import {
  addVehicle,
  fetchMostRecentVehicle,
  fetchVehicles,
  fetchVehicleMakes,
  fetchVehicleModels,
  fetchVehicleYears,
  removeVehicle,
  resetVehicleAsyncStatuses,
  updateVehicle,
  updateVehicleType,
  setSelectedVehicleForSTPVisit,
  addSharedDriver,
  removeSharedDriver,
  promoteSharedDriverToPrimary,
  acceptBecomingPrimaryDriver,
} from './actions';

import type { DoneHandler } from '@m/effector-utils';
import type { APIResponse } from '@m/utils/http';

export type LicensePlate = {
  text: string;
  state: IdNameObject;
};

type VehicleState = {
  vehicles: Vehicle[] | null;
  makes: Make[];
  models: { [makeId: string]: Model[] };
  years: { [modelId: string]: ModelYear[] };
  selectedVehicleForSTP: Vehicle | null;
};

const initialState: VehicleState = {
  vehicles: null, // Will be [] if no vehicles, null if haven't retrieved yet
  makes: [],
  models: {},
  years: {},
  selectedVehicleForSTP: null,
};

const store = createStore(initialState);

function normalizeVehicle(vehicle: Vehicle) {
  const nextVehicle = { ...vehicle };
  if (vehicle.modelYear) {
    const nextModelYear = { ...vehicle.modelYear };
    // assign year to name as a string, since fuse.js can only search strings
    nextModelYear.name = vehicle?.modelYear?.year?.toString();
    nextVehicle.modelYear = nextModelYear;
  }

  return nextVehicle;
}

type VehicleReducers = {
  fetchMostRecentVehicle: {
    action: Effect<Parameters<typeof fetchMostRecentVehicle>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchMostRecentVehicle>[0], VehicleState>;
  };
  fetchVehicles: {
    action: Effect<Parameters<typeof fetchVehicles>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchVehicles>[0], VehicleState>;
  };
  fetchVehicleMakes: {
    action: Effect<Parameters<typeof fetchVehicleMakes>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchVehicleMakes>[0], VehicleState>;
  };
  fetchVehicleModels: {
    action: Effect<Parameters<typeof fetchVehicleModels>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchVehicleModels>[0], VehicleState>;
  };
  fetchVehicleYears: {
    action: Effect<Parameters<typeof fetchVehicleYears>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchVehicleYears>[0], VehicleState>;
  };
  addVehicle: {
    action: Effect<Parameters<typeof addVehicle>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof addVehicle>[0], VehicleState>;
  };
  updateVehicle: {
    action: Effect<Parameters<typeof updateVehicle>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateVehicle>[0], VehicleState>;
  };
  updateVehicleType: {
    action: Effect<Parameters<typeof updateVehicleType>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateVehicleType>[0], VehicleState>;
  };
  removeVehicle: {
    action: Effect<Parameters<typeof removeVehicle>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateVehicle>[0], VehicleState>;
  };
  setSelectedVehicleForSTPVisit: {
    action: Event<Vehicle>;
    reducer: (state: VehicleState, vehicle: Vehicle) => VehicleState;
  };
  addSharedDriver: {
    action: Effect<Parameters<typeof addSharedDriver>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof addSharedDriver>[0], VehicleState>;
  };
  removeSharedDriver: {
    action: Effect<Parameters<typeof removeSharedDriver>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof removeSharedDriver>[0], VehicleState>;
  };
  promoteSharedDriverToPrimary: {
    action: Effect<Parameters<typeof promoteSharedDriverToPrimary>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof promoteSharedDriverToPrimary>[0], VehicleState>;
  };
  acceptBecomingPrimaryDriver: {
    action: Effect<Parameters<typeof acceptBecomingPrimaryDriver>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof acceptBecomingPrimaryDriver>[0], VehicleState>;
  };
};

export const reducers: VehicleReducers = {
  fetchMostRecentVehicle: {
    action: fetchMostRecentVehicle,
    done: (
      state,
      { result: { success, data } = {} } = {
        result: { success: false, data: {} },
        params: undefined,
      },
    ) => {
      if (success) {
        const { vehicle } = data;
        if (vehicle.modelYear) {
          const nextModelYear = { ...vehicle.modelYear };
          // assign year to name as a string, since fuse.js can only search strings
          nextModelYear.name = vehicle.modelYear.year.toString();
          vehicle.modelYear = nextModelYear;
        }

        return {
          ...state,
          vehicle,
        };
      }
      return {
        ...state,
        vehicle: null, // no vehicle found
      };
    },
  },
  fetchVehicles: {
    action: fetchVehicles,
    done: (state, { result: { success, data } }) => {
      const nextVehicles = data.map((vehicle: Vehicle) => {
        const updatedVehicle = { ...vehicle };
        if (vehicle.modelYear) {
          const nextModelYear = { ...vehicle.modelYear };
          // assign year to name as a string, since fuse.js can only search strings
          nextModelYear.name = vehicle?.modelYear?.year?.toString();
          updatedVehicle.modelYear = nextModelYear;
        }
        return updatedVehicle;
      });
      const mostRecentVehicle = success && data[0];
      return {
        ...state,
        vehicles: nextVehicles || [],
        vehicle: mostRecentVehicle || null,
      };
    },
  },
  fetchVehicleMakes: {
    action: fetchVehicleMakes,
    done: (state, { result: { success, data } }) => ({
      ...state,
      makes: success ? data.makes : [],
    }),
  },
  fetchVehicleModels: {
    action: fetchVehicleModels,
    done: (state, { result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          models: {
            [data.makeId]: data.models,
          },
        };
      }
      return state;
    },
  },
  fetchVehicleYears: {
    action: fetchVehicleYears,
    done: (state, { result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          years: {
            [data.modelId]: data.modelYears.map((modelYear: ModelYear) => {
              // assign year to name as a string to match picker item type
              if (modelYear !== null) {
                const nextModelYear = { ...modelYear };
                nextModelYear.name = modelYear && modelYear.year ? modelYear.year.toString() : '';
                return nextModelYear;
              }
              return modelYear;
            }),
          },
        };
      }
      return state;
    },
  },
  addVehicle: {
    action: addVehicle,
    done: (state, { result: { success, data } }) => {
      if (success) {
        const nextVehicles = state.vehicles ? [...state.vehicles] : [];
        nextVehicles.push(normalizeVehicle(data.vehicle));
        return {
          ...state,
          vehicles: nextVehicles,
        };
      }
      return state;
    },
  },
  updateVehicle: {
    action: updateVehicle,
    done: (state, { params: { vehicleId }, result: { success, data } }) => {
      if (success) {
        const nextVehicles = state.vehicles ? [...state.vehicles] : [];
        const vehicleIndex = nextVehicles.findIndex((v) => v.id === vehicleId);
        nextVehicles[vehicleIndex] = normalizeVehicle(data.vehicle);
        return {
          ...state,
          vehicles: nextVehicles,
        };
      }
      return state;
    },
  },
  updateVehicleType: {
    action: updateVehicleType,
    done: (state, { params: { vehicleId }, result: { success, data } }) => {
      if (success) {
        const nextVehicles = state.vehicles ? [...state.vehicles] : [];
        const vehicleIndex = nextVehicles.findIndex((v) => v.id === vehicleId);
        nextVehicles[vehicleIndex] = normalizeVehicle(data.vehicle);
        return {
          ...state,
          vehicles: nextVehicles,
        };
      }
      return state;
    },
  },
  removeVehicle: {
    action: removeVehicle,
    done: (state, { params: { vehicleId }, result: { success } }) => {
      if (success) {
        const nextVehicles = state.vehicles ? [...state.vehicles] : [];
        nextVehicles.splice(
          nextVehicles.findIndex((v) => v.id === vehicleId),
          1,
        );
        return {
          ...state,
          vehicles: nextVehicles,
        };
      }
      return state;
    },
  },
  setSelectedVehicleForSTPVisit: {
    action: setSelectedVehicleForSTPVisit,
    reducer: (state, selectedVehicle) => ({
      ...state,
      selectedVehicleForSTP: selectedVehicle,
    }),
  },
  addSharedDriver: {
    action: addSharedDriver,
    done: (state, { params, result: { success, data } }) => {
      let vehicleId: number;
      const nextVehicles = state.vehicles ? [...state.vehicles] : [];

      if (data && data.vehicleId) {
        vehicleId = data.vehicleId;
        const updatedVehicle = state.vehicles?.filter((v) => v.id === vehicleId)[0];

        if (updatedVehicle && updatedVehicle.drivers) {
          const newDrivers = updatedVehicle.drivers.filter(
            (driver) => driver.userId !== data.userId,
          );
          newDrivers.push({ ...data });
          updatedVehicle.drivers = newDrivers;
        }

        if (updatedVehicle) {
          nextVehicles.splice(
            nextVehicles.findIndex((v) => v.id === vehicleId),
            1,
            updatedVehicle,
          );

          return {
            ...state,
            vehicles: nextVehicles,
          };
        }
      }

      return state;
    },
  },

  removeSharedDriver: {
    action: removeSharedDriver,
    done: (state, { params: { vehicleId, userId }, result }) => {
      const nextVehicles = state.vehicles ? [...state.vehicles] : [];

      if (result.success) {
        const updatedVehicle = state.vehicles?.filter((v) => v.id === vehicleId)[0];

        if (updatedVehicle && updatedVehicle.drivers) {
          const newDrivers = updatedVehicle.drivers.filter((driver) => driver.userId !== userId);
          updatedVehicle.drivers = newDrivers;

          nextVehicles.splice(
            nextVehicles.findIndex((v) => v.id === vehicleId),
            1,
            updatedVehicle,
          );

          return {
            ...state,
            vehicles: nextVehicles,
          };
        }
      }

      return state;
    },
  },

  promoteSharedDriverToPrimary: {
    action: promoteSharedDriverToPrimary,
    done: (state, { params: { vehicleId, userId }, result }) => state,
  },

  acceptBecomingPrimaryDriver: {
    action: acceptBecomingPrimaryDriver,
    done: (state, { params: { vehicleId, requestId }, result }) => {
      if (result.success) {
        const updatedVhicle = { ...result.data.vehicle };
        const filterOldVehicle = state.vehicles
          ? state.vehicles.filter((v: Vehicle) => v.id !== vehicleId)
          : [];
        return { ...state, vehicles: [updatedVhicle, ...filterOldVehicle] };
      }
      return state;
    },
  },
};

const asyncResetReducers = [logout.done, resetVehicleAsyncStatuses];

store.reset(logout.done);

export default applyReducers({ store, reducers, asyncResetReducers });
