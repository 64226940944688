// Payment Providers
export const SQUARE_ID = 1;
export const STRIPE_ID = 2;

export const PAYMENT_METHOD_CARD_ID = 1;
export const PAYMENT_METHOD_BANK_ID = 2;

export enum CardBrand {
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DISCOVER = 'DISCOVER',
  DISCOVER_DINERS = 'DISCOVER_DINERS',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  JCB = 'JCB',
}

export const formattedBrandNameMap: { [key in CardBrand]: string } = {
  AMERICAN_EXPRESS: 'Amex',
  DISCOVER: 'Discover',
  DISCOVER_DINERS: 'Diners',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  JCB: 'JCB',
};

export const formatCardBrand = (cardBrand: CardBrand | null) =>
  // @ts-ignore
  formattedBrandNameMap[cardBrand.toUpperCase()] || 'Card';
