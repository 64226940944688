import { lazy, Suspense, useEffect } from 'react';

import AppAlert from '@m/components/AppAlert';
import BottomAppAlert from '@m/components/BottomAppAlert';
import { useHeapIdentifyUser } from '@m/hooks/useHeapIdentifyUser';
import useVisitPolling from '@m/hooks/useVisitPolling';
import { qrcodeStore } from '@m/state/qrcode';
import { useUnit } from 'effector-react';

import { useClosestMetropolisRegion } from 'apps/customer/hooks/useClosestMetropolisRegion';
import { useInitialDataForUser } from 'apps/customer/hooks/useInitialDataForUser';
import { useRefreshTodoItems } from 'apps/customer/hooks/useRefreshTodoItems';
import { useUserInitialization } from 'apps/customer/hooks/useUserInitialization';
import CustomerRoutes from 'apps/customer/routes/CustomerRoutes';
import { uiStore } from 'apps/customer/state/ui';
import { userStore } from 'apps/customer/state/user';
import { fetchTipSettings, visitStore } from 'apps/customer/state/visit';
import { userAnalytics } from 'utils/userAnalytics';

import useDoesUserHaveSubscriptions from './hooks/useDoesUserHaveSubscriptions';
import useIsFirstTimeUser from './hooks/useIsFirstTimeUser';

import type { User } from '@m/types/api';

const SideMenu = lazy(() => import('apps/customer/components/SideMenu'));
const AppFullModal = lazy(() => import('apps/customer/components/AppFullModal'));
const UserAgreementSheet = lazy(() => import('apps/customer/components/AgreementSheet'));

function Customer() {
  const { isAppModalVisible } = useUnit(uiStore);

  const {
    user,
    status: { parseInvitationCodeSuccess, getUserSuccess },
    authenticated,
  } = useUnit(userStore);

  const { mostRecentVisit } = useUnit(visitStore);

  const {
    status: { handleQRCodeSuccess },
  } = useUnit(qrcodeStore);

  const { id: visitId, open: visitOpen } = mostRecentVisit || {};

  /* hide initial loader */
  useEffect(() => {
    if (
      authenticated !== null ||
      getUserSuccess !== null ||
      parseInvitationCodeSuccess !== null ||
      handleQRCodeSuccess !== null
    ) {
      document.getElementById('initial-loader')?.remove();
    }
  }, [authenticated, getUserSuccess, parseInvitationCodeSuccess, handleQRCodeSuccess]);

  useEffect(() => {
    if (authenticated && user) {
      userAnalytics.setUser(user as User);
    }
  }, [authenticated, user]);

  /* Init data */
  useUserInitialization();
  useRefreshTodoItems();
  const userDataInitialized = useInitialDataForUser(user?.id);
  useVisitPolling({ interval: 4000, enabled: !!authenticated });

  /* Handle region to display */
  useClosestMetropolisRegion();

  /* Heap tracking */
  useHeapIdentifyUser({
    authenticated,
    // TODO: fix userStore user type
    user: user as User,
  });

  /* Init Heap first-time-user  hook */
  useIsFirstTimeUser();
  useDoesUserHaveSubscriptions();

  /* Fetch visit settings */
  useEffect(() => {
    if (visitId) {
      fetchTipSettings({ visitId });
    }
  }, [visitId]);

  // authenticated is null in the initial render cycles,
  // and we intended to render nothing.
  return (
    <>
      <AppAlert />

      {authenticated && (
        <Suspense fallback={null}>
          <SideMenu
            showConfirmation={visitOpen}
            firstName={user.firstName}
            lastName={user.lastName}
          />
        </Suspense>
      )}

      {authenticated && (
        <Suspense fallback={null}>
          <UserAgreementSheet />
        </Suspense>
      )}

      <CustomerRoutes userDataInitialized={userDataInitialized} />

      <BottomAppAlert />

      {isAppModalVisible && (
        <Suspense fallback={null}>
          <AppFullModal />
        </Suspense>
      )}
    </>
  );
}

export default Customer;
