import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';

import { fetchTodoItems, todoStore } from 'apps/customer/state/todo';
import { userStore } from 'apps/customer/state/user';
import { visitStore } from 'apps/customer/state/visit';

export function useRefreshTodoItems() {
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);

  const { visits } = useUnit(visitStore);
  const { user } = useUnit(userStore);
  const {
    hasPendingTasks,
    status: { fetchTodoItemsPending },
  } = useUnit(todoStore);

  const { id: userId } = user;

  const { transactionPaymentStatus } = visits?.[0] || {};

  const { id: transactionPaymentStatusId } = transactionPaymentStatus || {};

  // Only fetch todos if we didn't receive todos from the initialize API (eqless QR flow)
  useEffect(() => {
    if (userId !== currentUserId) {
      setCurrentUserId(userId || null);
      if (userId) {
        fetchTodoItems();
      }
    }
  }, [userId, currentUserId]);

  // Re-visit to see when the best time to fetch a todo is
  // Checks todo if visit payment status changes
  // Handles case where user has app open and leaves the site. we want to show the alert icon
  useEffect(() => {
    // If already fetching todos or has pending tasks, don't fetch again
    if (transactionPaymentStatusId && !fetchTodoItemsPending && !hasPendingTasks) {
      fetchTodoItems();
    }
    // Purposely leaving out other deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionPaymentStatusId]);
}
