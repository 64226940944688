import { apiFetch } from '@m/utils/http';

// we can potentially add return types
const TodoService = {
  getTodos: async () => apiFetch('/customer/todo'),

  getTodosV2: async () =>
    apiFetch('/v2/customer/todo', {
      method: 'GET',
    }),

  getPaymentOverdueTask: () => apiFetch('/customer/payment-due'),

  joinFlowOptOut: () => apiFetch('/customer/join/opt-out', { method: 'POST' }),

  /**
   * believe this is only used in the missed entry flow. need to confirm with BE what this does
   * @param customerTaskId
   */
  postMissedEntryOptOut: ({ customerTaskId }: { customerTaskId: number }) =>
    apiFetch(`/customer/task/${customerTaskId}/opt-out`, {
      method: 'POST',
    }),

  postConfirmMissedEntryStartVisit: ({
    missedEntryStartVisitTaskId,
    vehicleId,
  }: {
    missedEntryStartVisitTaskId: number;
    vehicleId: number;
  }) =>
    apiFetch(`/customer/missed-entry-start-visit/${missedEntryStartVisitTaskId}/confirm`, {
      method: 'POST',
      body: { vehicleId },
    }),
};

export default TodoService;
