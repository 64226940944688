import { Suspense } from 'react';

import { Route } from 'react-router-dom';

import type { RouteProps } from 'react-router-dom';

export function LazyRoute({
  children,
  ...routeProps
}: Omit<RouteProps, 'children'> & { children: React.ReactNode }) {
  return (
    <Route {...routeProps}>
      <Suspense fallback={null}>{children}</Suspense>
    </Route>
  );
}
