/**
 * Public paths
 */
export enum PublicRoutes {
  Terms = '/terms',
  Privacy = '/privacy',
  DoNotSell = '/do-not-sell',
  Help = '/help',
  Claim = '/claim/:shortCode',
}

export const publicPaths = Object.values(PublicRoutes);

/**
 * Protected (Must be logged in aka 'authorized') paths
 */
export enum ProtectedRoutes {
  ForwardSlash = '/',
  VisitPage = '/visit',
  PaymentPage = '/payment',
  ProfilePage = '/profile',
  AccountDeletePage = '/profile/terms-and-privacy',
  MembershipPage = '/membership',
  RedeemPage = '/redeem',
  VehiclePage = '/vehicle',
  VisitReceiptPage = '/visit/:visitUuid',
  DashboardPage = '/dashboard',
  ConfirmMissedEntryPage = '/confirm-entry',
  PromotionsPage = '/promo',
  ReceiptPage = '/receipt/:invoiceUuid',
}

export const protectedPaths = Object.values(ProtectedRoutes);

/**
 * DefaultOnboardingRoutes is the default sign up flow when no onboarding tasks are required
 */
export enum DefaultOnboardingRoutes {
  SignIn = '/sign-in',
  Verify = '/verify',
  SignUp = '/sign-up',
  Vehicle = '/sign-up/add-vehicle',
  PaymentMethod = '/sign-up/add-payment',
}

export const defaultOnboardingPaths = Object.values(DefaultOnboardingRoutes);

/**
 * StartParkingOnboardingRoutes are used when the backend sends us onboarding tasks (todos)
 */
export enum StartParkingOnboardingRoutes {
  Onboard = '/onboard',
  SelectVisitDuration = '/select-visit-duration',
  SignIn = '/auth/sign-in',
  Verification = '/auth/verify',
  AddVehicle = '/onboard/add-vehicle',
  AddEmail = '/onboard/add-email',
  SelectVehicle = '/onboard/select-vehicle',
  PaymentMethod = '/onboard/add-payment',
}

export const startParkingOnboardingPaths = Object.values(StartParkingOnboardingRoutes);

/**
 * /tasks is only used for the required Payment Due TODO item (user has to pay for unpaid visit)
 */

export enum TaskItemRoutes {
  Tasks = '/tasks',
}

export const taskItemPaths = Object.values(TaskItemRoutes);

export const authPaths = [...publicPaths, ...protectedPaths];
