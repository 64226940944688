import { useEffect, useCallback, useState } from 'react';

import { alertStore, hideBottomAlert } from '@m/state/alert';
import { useUnit } from 'effector-react';

import { AlertBox, AlertContainer } from './BottomAppAlert.styled';

import type { AlertContainerProps } from './BottomAppAlert.styled';

const ALERT_DURATION = 6000;

export default function BottomAppAlert(props: AlertContainerProps) {
  const [hideTimer, setHideTimer] = useState<number>();

  const { bottomAlert } = useUnit(alertStore);

  const { visible = false, label, type, customAlert, position } = bottomAlert || {};

  // Clear the previous timer if another timer is set
  useEffect(() => () => clearTimeout(hideTimer), [hideTimer]);

  useEffect(() => {
    if (visible) {
      const timer = window.setTimeout(() => {
        hideBottomAlert();
      }, ALERT_DURATION);
      setHideTimer(timer);
    }
  }, [visible, label, type]);

  const handleDismiss = useCallback(() => {
    hideBottomAlert();
    clearTimeout(hideTimer);
  }, [hideTimer]);

  return (
    <AlertBox visible={visible} position={position}>
      {customAlert || (
        <AlertContainer
          {...props}
          tabIndex={0}
          onTouchStart={handleDismiss}
          onMouseDown={handleDismiss}
          onKeyPress={handleDismiss}
          visible={visible}
          error={type === 'error'}
          warning={type === 'warning'}
          success={type === 'success'}
          successBgColor="#00A556"
          successFgColor="#fff"
        >
          {label}
        </AlertContainer>
      )}
    </AlertBox>
  );
}
