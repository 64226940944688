export const DASHBOARD = 'DASHBOARD';
export const HELP = 'HELP';
export const MEMBERSHIP = 'MEMBERSHIP';
export const PARKING_DETAIL = 'PARKING_DETAIL';
export const PAYMENT = 'PAYMENT';
export const PROFILE = 'PROFILE';
export const REDEEM = 'REDEEM'; // Currently only used for Enterprise
export const HISTORY = 'HISTORY';
export const VEHICLES = 'VEHICLES';
export const START_PARKING = 'START_PARKING';
export const PROMO = 'PROMO';

export const allMenuItemNames = [
  DASHBOARD,
  HELP,
  MEMBERSHIP,
  PARKING_DETAIL,
  PAYMENT,
  PROFILE,
  REDEEM,
  HISTORY,
  VEHICLES,
  START_PARKING,
  PROMO,
];

export type AllMenuItemNames = (typeof allMenuItemNames)[number];
