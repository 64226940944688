/**
 * Transaction Payment Statuses
 */

export const UNKNOWN = 'UNKNOWN';
export const VISIT_OPEN = 'VISIT_OPEN';
export const PAYMENT_DUE = 'PAYMENT_DUE';
export const PAYMENT_COMPLETED = 'PAYMENT_COMPLETED';
export const PAYMENT_NOT_REQUIRED = 'PAYMENT_NOT_REQUIRED';
export const PAYMENT_FOREGONE = 'PAYMENT_FOREGONE';

export const UNKNOWN_ID = 0;
export const VISIT_OPEN_ID = 1;
export const PAYMENT_DUE_ID = 2;
export const PAYMENT_COMPLETED_ID = 3;
export const PAYMENT_NOT_REQUIRED_ID = 4;
export const PAYMENT_FOREGONE_ID = 5;
