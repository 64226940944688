import { SELF_PARK } from '@m/constants/ParkingTypes';
import { SELECT_VISIT_DURATION_TODO } from '@m/constants/TodoTypes';
import { VISIT_VISION } from '@m/constants/VisitType';
import {
  isJoinLinkQR,
  isMissedEntryStartVisitQR,
  isStartVisitQR,
} from '@m/utils/guards/qrCodeRespType';
import { heapClearEventProperties } from '@m/utils/heap';
import { apiFetch } from '@m/utils/http';
import { getUser, setUser } from 'apps/customer/state/user';
import {
  trackIsMissedEntryEnabled,
  trackParkingType,
  trackQRSiteId,
  trackQRSiteName,
  trackRateType,
  trackUserLoggedIn,
  trackVisitType,
} from 'apps/customer/utils/heapOnboardTracking';
import { createEffect } from 'effector';

import type { QRCodeResp } from '@m/types/api';

/**
 * parses the QR delegation response we receive from the backend
 * @param qrCode - qrDelagation object for join or validation QRs
 *
 * @return success - if qrCode is valid
 */
export function parseQRDelegation(qrCode: QRCodeResp) {
  if (isStartVisitQR(qrCode)) {
    const {
      startVisitView: { todoItems, user },
      site,
    } = qrCode;

    setUser({ user });

    // need to set event properties before page navigation,
    // otherwise heap won't have the data in time
    trackVisitType('stp');
    trackUserLoggedIn(user.isRegistered);
    if (site) {
      trackQRSiteId(site.id);
      trackQRSiteName(site.name || 'N/A');
    }
    const selectVisitDurationTask = todoItems.find(
      (task) => task.type === SELECT_VISIT_DURATION_TODO,
    );
    if (selectVisitDurationTask?.type === SELECT_VISIT_DURATION_TODO) {
      trackRateType(selectVisitDurationTask.equipmentlessOptionsView.rate.type.name);
    }
    return true;
  }
  if (isJoinLinkQR(qrCode)) {
    const { userInfo, site, rate } = qrCode;

    const { isRegistered } = userInfo;

    // userInfo should be all the data the customer uses
    // @ts-ignore
    setUser({ user: userInfo });
    trackUserLoggedIn(isRegistered);
    trackVisitType(VISIT_VISION);
    trackParkingType(SELF_PARK);
    trackIsMissedEntryEnabled(false);
    if (rate) {
      trackRateType(rate.type.name);
    }
    if (site) {
      trackQRSiteId(site.id);
      trackQRSiteName(site.name || 'N/A');
    }
    return true;
  }
  if (isMissedEntryStartVisitQR(qrCode)) {
    const { userInfo, site, rate } = qrCode;
    const { isRegistered } = userInfo;

    // userInfo should be all the data the customer uses
    // @ts-ignore
    setUser({ user: userInfo });
    trackUserLoggedIn(isRegistered);
    trackVisitType(VISIT_VISION);
    trackParkingType(SELF_PARK);
    trackIsMissedEntryEnabled(true);
    if (rate) {
      trackRateType(rate.type.name);
    }
    if (site) {
      trackQRSiteId(site.id);
      trackQRSiteName(site.name || 'N/A');
    }
    return true;
  }

  return false;
}
/**
 * Checks what kind of QR code is being scanned
 * Current QR types are StartVisitView and JoinLinkView
 *
 * Join Link QR - route user to /visit page after sigining in
 * Start Visit QR - either create a new session (dummy user) or link the logged in user
 */
export const handleQRCode = createEffect({
  handler: async ({ uuid }: { uuid: string }) => {
    const response = await apiFetch(`/qr/delegate/${uuid}`);

    if (response.success) {
      heapClearEventProperties();
      const qrCode: QRCodeResp = response.data;
      parseQRDelegation(qrCode);
    } else {
      getUser();
    }

    return response;
  },
});

export default null;
