/**
 * Utility to run fetch() requests on a worker thread.
 *
 * Although the fetch() api is asynchronous, it is
 * still thread blocking when run on the main thread.
 * If there is a high volume of requests (e.g. when
 * fetching tramsactions), this can block execution on
 * the main thread and cause jank and lag.
 *
 * `workerize` is a module that allows easy creation
 * of workers without having to deal with messaging
 * between the main thread and worker thread.
 * https://github.com/developit/workerize
 */

// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/workerize` if it exists or...
import workerize from 'workerize';

const worker = workerize(`
  export function wFetch(url, options) {
    return fetch(url, options).then(res => res.json());
  }
`);

const fetchWorker = async (url: any, options?: any) => {
  const json = await worker.wFetch(url, options);
  return json;
};

export default fetchWorker;
