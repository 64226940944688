import { STRIPE_ID } from '@m/constants/PaymentEnums';
import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

import { fetchCustomerVisits } from 'apps/customer/state/visit';

export const fetchPaymentMethods = createEffect({
  handler: ({ userId }: { userId: number }) => apiFetch(`/user/${userId}/payment-methods`),
});

export const addStripeCard = createEffect({
  handler: ({
    userId,
    cardToken,
    preAuth,
  }: {
    userId?: number;
    cardToken: string;
    preAuth?: 'sync' | 'async';
  }) =>
    apiFetch(
      preAuth
        ? `/user/${userId}/payment-methods?preAuth=${preAuth}`
        : `/user/${userId}/payment-methods`,
      {
        method: 'POST',
        body: {
          cardToken,
          paymentProvider: {
            id: STRIPE_ID,
            name: 'Stripe',
          },
        },
      },
    ),
});

export const setDefaultPaymentMethod = createEffect({
  handler: ({ paymentMethodId }: { paymentMethodId: number }) =>
    apiFetch('/user/default-payment-method', {
      method: 'POST',
      params: { paymentMethodId },
    }),
});

export const deletePaymentMethod = createEffect({
  handler: ({ paymentMethodId }: { paymentMethodId: number }) =>
    apiFetch(`/customer/payment-method/${paymentMethodId}`, { method: 'DELETE' }),
});

export const payForOverdueVisits = createEffect({
  handler: async ({
    cardToken,
    cardId,
    numItems,
  }: {
    cardToken?: string;
    cardId?: number;
    numItems: number;
  }) => {
    let body;

    if (cardToken) {
      body = {
        cardToken,
        paymentProvider: {
          id: STRIPE_ID,
          name: 'Stripe',
        },
      };
    }

    if (cardId) {
      body = {
        paymentMethodId: cardId,
      };
    }

    const response = await apiFetch(`/customer/payment-due/pay?numItems=${numItems}`, {
      method: 'POST',
      body,
    });

    if (response.success) {
      await fetchCustomerVisits();
    }

    return response;
  },
});
