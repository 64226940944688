export const OTHER = 'Other';
export const UNAUTHENTICATED = 'Unauthenticated';
export const UNAUTHORIZED = 'Unauthorized';
export const AUTHENTICATION_FAILED = 'Authentication Failed';
export const RESOURCE_NOT_FOUND = 'Resource Not Found';
export const BAD_DATA = 'Bad Data';
export const BAD_REQUEST = 'Bad Request';
export const EXTERNAL_FAILURE = 'External Failure';
export const PAYMENT_PROCESSING_FAILURE = 'Payment Processing Failure';

export const OTHER_ID = 0;
export const UNAUTHENTICATED_ID = 1;
export const UNAUTHORIZED_ID = 2;
export const AUTHENTICATION_FAILED_ID = 3;
export const RESOURCE_NOT_FOUND_ID = 4;
export const BAD_DATA_ID = 5;
export const BAD_REQUEST_ID = 6;
export const EXTERNAL_FAILURE_ID = 7;
export const PAYMENT_PROCESSING_FAILURE_ID = 8;

type ResponseErrorCodeId =
  | typeof OTHER_ID
  | typeof UNAUTHENTICATED_ID
  | typeof UNAUTHORIZED_ID
  | typeof AUTHENTICATION_FAILED_ID
  | typeof RESOURCE_NOT_FOUND_ID
  | typeof BAD_DATA_ID
  | typeof BAD_REQUEST_ID
  | typeof EXTERNAL_FAILURE_ID
  | typeof PAYMENT_PROCESSING_FAILURE_ID;

type ResponseErrorCodeName =
  | typeof OTHER
  | typeof UNAUTHENTICATED
  | typeof UNAUTHORIZED
  | typeof AUTHENTICATION_FAILED
  | typeof RESOURCE_NOT_FOUND
  | typeof BAD_DATA
  | typeof BAD_REQUEST
  | typeof EXTERNAL_FAILURE
  | typeof PAYMENT_PROCESSING_FAILURE;

export type ResponseErrorCode = {
  id: ResponseErrorCodeId;
  name: ResponseErrorCodeName;
};
