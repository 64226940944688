export const SELF_PARK_SIGN_UP = 'SELF_PARK_SIGN_UP';
export const SELF_PARK_SIGN_UP_AGAIN = 'SELF_PARK_SIGN_UP_AGAIN';
export const VALET_CHECK_IN = 'VALET_CHECK_IN';
export const VALET_RESEND_INVITE = 'VALET_RESEND_INVITE';
export const VALET_REQUEST_RETRIEVAL_AND_READY_PAYMENT =
  'VALET_REQUEST_RETRIEVAL_AND_READY_PAYMENT';
export const PROCESS_PAYMENT = 'PROCESS_PAYMENT';
export const VALIDATE = 'VALIDATE';
export const VALET_ACCEPT = 'VALET_ACCEPT';
export const VALET_RETRIEVED = 'VALET_RETRIEVED';
export const VALET_CHECK_OUT = 'VALET_CHECK_OUT';
export const CHECK_OUT = 'CHECK_OUT';
export const FORCE_CLOSE = 'FORCE_CLOSE';
export const SET_TIP = 'SET_TIP';
export const VEND_GATE = 'VEND_GATE';

// Correction Actions
export const CORRECTION = 'CORRECTION';
export const CORRECTION_INVALID = 'CORRECTION_INVALID';
export const CORRECTION_MISREAD = 'CORRECTION_MISREAD';
export const CORRECTION_CHANGE_TO_EXIT = 'CORRECTION_CHANGE_TO_EXIT'; // deprecated
export const CORRECTION_CHANGE_TO_ENTRY = 'CORRECTION_CHANGE_TO_ENTRY'; // deprecated
export const CORRECTION_DEALER_PLATE = 'CORRECTION_DEALER_PLATE';
export const CORRECTION_DUPLICATE = 'CORRECTION_DUPLICATE';
export const CORRECTION_MANUAL_PAIR = 'CORRECTION_MANUAL_PAIR';
export const CORRECTION_INCORRECT_ENTRY = 'CORRECTION_INCORRECT_ENTRY';
export const CORRECTION_INCORRECT_EXIT = 'CORRECTION_INCORRECT_EXIT';
export const CORRECTION_NO_MATCHING_PAIR = 'CORRECTION_NO_MATCHING_PAIR';
export const CORRECTION_NOT_A_VEHICLE = 'CORRECTION_NOT_A_VEHICLE';
export const CORRECTION_OTHER_ERROR = 'CORRECTION_OTHER_ERROR';
export const CORRECTION_UNREADABLE_PLATE = 'CORRECTION_UNREADABLE_PLATE';

export const ALL_AVAILABLE_CORRECTIONS = [
  CORRECTION,
  CORRECTION_INVALID,
  CORRECTION_MISREAD,
  CORRECTION_DEALER_PLATE,
  CORRECTION_DUPLICATE,
  CORRECTION_MANUAL_PAIR,
  CORRECTION_INCORRECT_ENTRY,
  CORRECTION_NO_MATCHING_PAIR,
  CORRECTION_NOT_A_VEHICLE,
  CORRECTION_OTHER_ERROR,
  CORRECTION_UNREADABLE_PLATE,
];

export const EDIT_POPOVER_CORRECTIONS = [
  CORRECTION_DUPLICATE,
  CORRECTION_INCORRECT_ENTRY,
  CORRECTION_NOT_A_VEHICLE,
  CORRECTION_UNREADABLE_PLATE,
  CORRECTION_NO_MATCHING_PAIR,
  CORRECTION_OTHER_ERROR,
];
