import { useEffect } from 'react';

import { getActiveSubscriptions } from '@m/state/subscription';
import { Site } from '@m/types/api';
import { useUnit } from 'effector-react';

import { userStore } from '../state/user';
import { trackDoesHaveSubcriptions } from '../utils/heapOnboardTracking';

import type { Subscription } from '@m/types/api';

const useDoesUserHaveSubscriptions = () => {
  const {
    user: { phoneNumber },
  } = useUnit(userStore);

  useEffect(() => {
    let isMonthlySubscriber = false;
    let isCompanySubscriber = false;
    if (phoneNumber) {
      getActiveSubscriptions().then((res) => {
        if (res.success && res.data) {
          res.data.forEach((each: { subscription: Subscription; site: Site }) => {
            if (each.subscription.details.enterpriseSubscriptionPlanId === null) {
              isMonthlySubscriber = true;
              return;
            }
            isCompanySubscriber = true;
          });
          trackDoesHaveSubcriptions({ isMonthlySubscriber, isCompanySubscriber });
        }
      });
    }
  }, [phoneNumber]);
};

export default useDoesUserHaveSubscriptions;
