import { AllMenuItemNames } from '@m/constants/CustomerAppSideMenuItemNames';
import { createEvent } from 'effector';

export const showSideMenu = createEvent();

export const hideSideMenu = createEvent();

export const setSideMenuActiveItem = createEvent<{ itemName: AllMenuItemNames }>();

export const toggleSideMenu = createEvent();

export const showLastVisitModal = createEvent();

export const hideLastVisitModal = createEvent();

export const showConfirmationModal = createEvent();

export const hideConfirmationModal = createEvent();

export const showHelpModal = createEvent();

export const hideHelpModal = createEvent();

export const showAppModal = createEvent<{
  content: JSX.Element;
  timeout?: number;
  styleOverrides?: string;
}>();

export const hideAppModal = createEvent<void>();

export const showAgreementSheet = createEvent();

export const hideAgreementSheet = createEvent();

export const setIsValetPaymentScreenOpen = createEvent<boolean>();

export const setIsValetCancelRequestScreenOpen = createEvent<boolean>();
