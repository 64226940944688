import headerPattern from '@m/assets/img/header-pattern-background.png';
import { StyledSystemProps, withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import { ExpandedContent } from 'apps/customer/components/StickyFooter/StickyFooter.styled';

export const Form = styled.form`
  position: relative;
  width: 100%;
`;

export const ExpandedForm = styled.form.attrs({ noValidate: true })`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div<StyledSystemProps>`
  position: relative;
  display: flex;
  height: 68px;
  border-bottom: 1px solid #000;
  margin-bottom: 32px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${withStyledSystem}
`;

export const GreetingBox = styled.div<StyledSystemProps>`
  margin-bottom: 32px;
  width: 100%;

  ${withStyledSystem}
`;

export const Title = styled.div<{ smallerFont?: boolean }>`
  font-size: ${(props) => (props.smallerFont ? 22 : 24)}px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: 0.005em;
  text-rendering: geometricPrecision;
`;

export const Message = styled.p`
  margin-block: 16px;
  margin-inline: 0;
  font-size: 16px;
  line-height: 20px;
  text-rendering: geometricPrecision;
`;
export const ScrimBackground = styled(ExpandedContent)<{ isScrimBackground?: boolean | null }>`
  position: relative;
  width: 100%;
  flex: 1;

  ${({ isScrimBackground = true }) =>
    isScrimBackground &&
    `
    max-width: 375px;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 200px;
      width: 100%;
      content: '';
      z-index: 0;
      display: block;
      background-image: linear-gradient(to bottom, rgba(255,255,255,0) 25%, rgba(255,255,255,1)),
                        url(${headerPattern});
      background-repeat: no-repeat;
      border-radius: inherit;
      
      @media (min-width: 400px) {
        background-image: linear-gradient(to bottom, rgba(255,255,255,0) 25%, rgba(255,255,255,1)),
                          linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0) 12%, rgba(255,255,255,0) 88%, rgba(255,255,255,1) 100%),
                          url(${headerPattern});
      }
    }
  `}
`;

// Needed to bring content above the scrim background
export const StackedContent = styled.div<StyledSystemProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${withStyledSystem}
`;

export const SubmitWrapper = styled.button`
  width: 100%;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;

  & .disabled {
    opacity: 0.5;
  }
`;
