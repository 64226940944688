export const MISSED_ENTRY_START_VISIT_VIEW_TYPE = 'MissedEntryStartVisitView';
export const START_VISIT_VIEW_TYPE = 'StartVisitView';
export const JOIN_LINK_VIEW_TYPE = 'JoinLinkView';

export type MissedEntryStartVisitViewType = typeof MISSED_ENTRY_START_VISIT_VIEW_TYPE;
export type StartVisitViewType = typeof START_VISIT_VIEW_TYPE;
export type JoinLinkViewType = typeof JOIN_LINK_VIEW_TYPE;

export type QRType =
  | typeof START_VISIT_VIEW_TYPE
  | typeof JOIN_LINK_VIEW_TYPE
  | typeof MISSED_ENTRY_START_VISIT_VIEW_TYPE;
