import distance from '@turf/distance';

type TurfUnits =
  | 'miles'
  | 'nauticalmiles'
  | 'degrees'
  | 'radians'
  | 'inches'
  | 'yards'
  | 'meters'
  | 'metres'
  | 'kilometers'
  | 'kilometres';

function getCoordinateDistance(pt1: any, pt2: any, units: TurfUnits = 'miles') {
  return distance(pt1, pt2, units);
}

export default getCoordinateDistance;
