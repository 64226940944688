import { heapTrackProperties } from '@m/utils/heap';

export const SCANNED_NATIVE = 'native';
export const SCANNED_ACTIVE_VISIT_PAGE = 'activeVisitPage';

type ScanLocation = typeof SCANNED_NATIVE | typeof SCANNED_ACTIVE_VISIT_PAGE;

export const trackValidationScan = ({
  validationScanSource,
  pendingValidationUuid,
}: {
  validationScanSource: ScanLocation;
  pendingValidationUuid: string;
}) => {
  heapTrackProperties('Validation Scanned', {
    validationScanSource,
    pendingValidationUuid,
  });
};

export const trackValidationResponse = ({
  appliedValidationSuccess,
  appliedValidationUuid,
  attemptedValidationId,
  appliedValidationTransactionId,
  appliedValidationSiteId,
  appliedValidationErrorMessage,
}: {
  appliedValidationSuccess: boolean;
  appliedValidationUuid?: string;

  /** can be success or failed validation id. we don't always have failed validationIds */
  attemptedValidationId?: number;
  appliedValidationTransactionId: number | 'null';
  appliedValidationSiteId: number | 'null';
  appliedValidationErrorMessage?: string;
}) => {
  heapTrackProperties('Validation Scan Response', {
    appliedValidationSuccess: String(appliedValidationSuccess),
    appliedValidationUuid,
    attemptedValidationId,
    appliedValidationTransactionId,
    appliedValidationSiteId,
    appliedValidationErrorMessage,
  });
};
