export const PAYMENT_OVERDUE_TODO = 'paymentDueFollowup';
export const ADD_NAME_EMAIL_TODO = 'addNameEmail';
export const ADD_PHONE_NUMBER_TODO = 'addPhoneNumber';
export const ADD_VEHICLE_TODO = 'addVehicle';
export const ADD_PAYMENT_TODO = 'addPaymentMethod';
export const AGREEMENT_TODO = 'confirmUserAgreement';
export const SELECT_VISIT_DURATION_TODO = 'selectVisitDuration';
export const CONFIRM_MISSED_ENTRY_START_VISIT_TODO = 'confirmMissedEntryStartVisit';
export const SELECT_VEHICLE_TODO = 'selectVehicle';
export const CONFIRM_VISIT = 'confirmVisit';
