export const CAN = 'CAN'; // Canada
export const USA = 'USA'; // United States of America

export const AUS = 'AUS'; // Austin
export const BOL = 'BOL'; // Boulder
export const CHI = 'CHI'; // Chicago
export const CHT = 'CHT'; // Chattanooga
export const CLE = 'CLE'; // Cleveland
export const CLT = 'CLT'; // Charlotte
export const DAL = 'DAL'; // Dallas
export const DC = 'DC'; // NOVA/DC
export const DEN = 'DEN'; // Denver
export const DET = 'DET'; // Detroit
export const FW = 'FW'; // Fort Worth
export const HUS = 'HUS'; // Huston
export const KC = 'KC'; // Kansas City
export const KNX = 'KNX'; // Knoxville
export const LA = 'LA'; // Los Angeles
export const MEM = 'MEM'; // Memphis
export const MIA = 'MIA'; // Miami
export const NCAL = 'NCAL'; // Northern Cali
export const NSH = 'NSH'; // Nashville
export const NYC = 'NYC'; // New York
export const PIT = 'PIT'; // Pittsburg
export const ROA = 'ROA'; // Roanoke
export const SA = 'SA'; // San Antonio
export const SAC = 'SAC'; // Sacramento
export const SEA = 'SEA'; // Seattle
export const SLC = 'SLC'; // Salt Lake City
export const SP = 'SP'; // Sait Paul
export const STL = 'STL'; // St. Louis

export type MetropolisRegionName =
  | typeof AUS
  | typeof BOL
  | typeof CHT
  | typeof CLE
  | typeof CLT
  | typeof CHI
  | typeof DAL
  | typeof DC
  | typeof DEN
  | typeof DET
  | typeof FW
  | typeof HUS
  | typeof KC
  | typeof KNX
  | typeof LA
  | typeof MEM
  | typeof MIA
  | typeof NCAL
  | typeof NSH
  | typeof NYC
  | typeof PIT
  | typeof ROA
  | typeof SA
  | typeof SAC
  | typeof SEA
  | typeof SLC
  | typeof SP
  | typeof STL;

export const CANSelecOption = { id: 1000, name: CAN };
export const USASelecOption = { id: 2000, name: USA };
