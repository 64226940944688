import styled from 'styled-components';

export const SkipButton = styled.button`
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 16px;

  padding: 10px 0 10px 10px;
`;

export const HelpBox = styled.div`
  margin: 10px 0 40px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  text-rendering: geometricPrecision;

  & > a {
    display: block;
    margin-top: 16px;
    color: ${(props) => props.theme.colors.blue6};
    text-rendering: geometricPrecision;
    font-weight: 700;
  }
`;

export const VehicleViewBackBtn = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-self: flex-start;
  justify-content: start;
  position: relative;
  top: -12px;
`;
