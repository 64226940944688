import { applyReducers } from '@m/effector-utils';
import { createStore, Effect } from 'effector';

import { handleQRCode } from './actions';

import type { QRType } from '@m/constants/QRTypes';
import type { DoneHandler } from '@m/effector-utils';
import type { APIResponse } from '@m/utils/http';

type State = {
  qrCodeType?: QRType;
};

const initialState: State = {};

const store = createStore(initialState);

type Reducers = {
  handleQRCode: {
    action: Effect<Parameters<typeof handleQRCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof handleQRCode>[0], APIResponse>;
  };
};

const reducers: Reducers = {
  handleQRCode: {
    action: handleQRCode,
    done: (state, { result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          qrCodeType: data.type,
        };
      }
      return state;
    },
  },
};

export default applyReducers({ store, reducers });
