// taken from site ViolationAction
// refactor: the backend should provide update methods
export const VIOLATION_CREATE = { id: 0, name: 'Create' as const };
export const VIOLATION_CONFIRM = { id: 1, name: 'Confirm' as const };
export const VIOLATION_EXPORT = { id: 2, name: 'Export' as const };
export const VIOLATION_IMPORT_MATCH = { id: 3, name: 'Import_Match' as const };
export const VIOLATION_IMPORT_MISS = { id: 4, name: 'Import_Miss' as const };
export const VIOLATION_MAIL = { id: 5, name: 'Mail' as const };
export const VIOLATION_EXPIRE = { id: 6, name: 'Expire' as const };
export const VIOLATION_PAYMENT = { id: 7, name: 'Payment' as const };
export const VIOLATION_DISPUTE = { id: 8, name: 'Dispute' as const };
export const VIOLATION_ACCEPT = { id: 9, name: 'Accept' as const };
export const VIOLATION_DECLINE = { id: 10, name: 'Decline' as const };
export const VIOLATION_CLOSE = { id: 11, name: 'Close' as const };
