import type { ReactNode } from 'react';

import { BottomSheet as Sheet } from 'react-spring-bottom-sheet';

import { BottomSheetStyles } from './BottomSheet.styled';

import type { BottomSheetProps as SheetProps } from 'react-spring-bottom-sheet';

export type BottomSheetProps = Omit<SheetProps, 'children'>;

type Props = {
  children: ReactNode;
};

export default function BottomSheet({
  snapPoints = ({ minHeight }) => minHeight,
  initialFocusRef = false,
  children,
  ...bottomSheetProps
}: BottomSheetProps & Props) {
  return (
    <>
      <BottomSheetStyles />
      <Sheet
        // without initialFocusRef, inputs in the bottom sheet will be auto focused,
        // which causes the keyboard to overlap the bottom sheet
        initialFocusRef={initialFocusRef}
        snapPoints={snapPoints}
        {...bottomSheetProps}
      >
        {children}
      </Sheet>
    </>
  );
}
